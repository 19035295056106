import Carousel from 'react-bootstrap/Carousel';

var heroData = [
  {
    id: 1,
    image: require('../assets/img-hero1.jpg'),
    title: 'Garanta Sua Vaga e Conquiste Seus Sonhos',
    description: '"Sua jornada para um futuro brilhante começa aqui! Garanta sua vaga e seja parte da nossa comunidade de aprendizado. Inscreva-se já! "',
    link: 'https://portalonline.icsgisabelinha.com/Publico/Candidaturas/Candidatura'
  },
  {
    id: 2,
    image: require('../assets/img-hero2.jpg'),
    title: 'Dê o Primeiro Passo para o Sucesso Acadêmico!',
    description: ' Está pronto para dar o próximo passo na sua educação? Inscreva-se agora e venha descobrir o que você é capaz de conquistar!',
    link: 'https://portalonline.icsgisabelinha.com/Publico/Candidaturas/Candidatura'
  },
  {
    id: 3,
    image: require('../assets/img-hero3.jpg'),
    title: 'Sua Jornada Começa Aqui: Faça Sua Inscrição!',
    description: 'Não deixe para amanhã o que pode mudar seu hoje! Inscreva-se e comece a construir o seu caminho para o sucesso. Juntos, vamos longe',
    link: 'https://portalonline.icsgisabelinha.com/Publico/Candidaturas/Candidatura'
  }
]

function AppHero() {
  return (
    <section id="home" className="hero-block">
       <Carousel>
          {
            heroData.map(hero => {
              return (
                <Carousel.Item key={hero.id}>
                  <img
                    className="d-block w-100"
                    src={hero.image}
                    alt={"slide " + hero.id}
                  />
                  <Carousel.Caption>
                    <h2>{hero.title}</h2>
                    <p style={{color:'#FFFFFF'}}>{hero.description}</p>
                    <a className="btn btn-primary" href={hero.link}>Clique aqui! <i className="fas fa-chevron-right"></i></a>
                  </Carousel.Caption>             
                </Carousel.Item>
              );
            })
          }
      </Carousel>
    </section>
  );
}

export default AppHero;